.login-pages .contact-us {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 24px;
	align-items: center;
	justify-content: center;
}
.login-pages .contact-us .left {
	display: flex;
	flex-direction: column;
	width: 40%;
	text-align: center;
}

.login-pages .contact-us .left h1{
	color: #0070C0 ;
}

.login-pages .contact-us .right {
	display: flex;
	flex-direction: column;
	width: 60%;
	text-align: center;
	gap: 8px;
}

.login-pages .contact-us .right .input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	gap: 4px;
}
.login-pages .contact-us .right .input p {
	margin: 0;
}

.login-pages .contact-us .right .input span {
	color: red;
}

.login-pages .contact-us .right .input .note {
	font-size: smaller;
}

.login-pages .contact-us .right .p-inputtext, .login-pages .contact-us .right .p-dropdown {
	width: 100%;
}

.login-pages .contact-us .right .p-button {
	align-self: flex-start;
}
