.topbar-menu .profile-image {
  max-height: 35px;
}

.topbar-menu .profile-name {
  line-height: 1.2rem;
}

.topbar-menu .profile-name small {
  font-size: 80%;
}

.topbar-menu .account-settings-btn {
  width: auto;
}
